.admission_tab_megamenu {
  width: 95vw;
  padding: 30px !important;
  left: -90% !important;
  transform: translateX(-50%) !important;
  overflow: hidden;
}

@media screen and (max-width: 1280px) {
  .admission_tab_megamenu {
    left: -90% !important;
    top: 0% !important;
    transform: translateX(-60%) scale(0.8) !important ;
  }
}
@media screen and (max-width: 768px) {
  .admission_tab_megamenu {
    left: unset !important;
    top: 100% !important;
    transform: translateX(0%) scale(1) !important ;
  }
}

// @media screen and (max-width: 992px) {
//   .admission_tab_megamenu {
//     padding: 0px !important;
//   }
// }

header .nav-item:hover .megamenu.hide_menu {
  visibility: hidden;
}
