.contact-btn {
  outline: none;
  border: none !important;
  background-color: #2ed47c !important;
  padding: 8px 9px !important;
  text-transform: capitalize !important;
  font-size: 13px !important;
  color: white !important;
}
@media screen and (max-width: 1200px) {
  .contact-btn {
    padding: 8px 9px !important;
    text-transform: capitalize !important;
    font-size: 13px !important;
  }

  .header-btn-col {
    display: none !important;
  }
}

@media screen and (max-width: 988px) {
  .contact-btn {
    padding: 4px 6px !important;
    text-transform: capitalize !important;
    font-size: 8px !important;
  }
}
