.direct_admission_container {
  display: flex;
  font-family: var(--alt-font);
  color: #828282;
  width: auto;
  min-width: 100%;
  transition: 0.5s;
  overflow: hidden;

  & .tab_section {
    font-weight: 500;
    width: 20%;
    min-width: 340px;
    box-shadow: 0px 3px 3px 2px rgba(213, 218, 223, 0.35);

    & .tab {
      display: flex;
      align-items: center;
      gap: 6px;
      padding: 16px;
      padding-bottom: 10px;
      border-bottom: 2px solid #eee;
      border-right: 2px solid #ffcf9d;
      cursor: pointer;
    }

    & .tab.active,
    .tab:hover {
      color: #f78c1f;
      background-color: #fff8f3;
      border: 2px solid #ffcf9d;
      border-right: none;
    }
  }

  & .tab_content {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-auto-rows: max-content;
    gap: 16px;
    overflow-y: auto;
    padding: 16px;
    background-color: #fff8f3;

    & a {
      // display: inline-block;
      width: 325px;
      white-space: nowrap;
      overflow: hidden;
      // text-overflow: ellipsis;

      &:hover {
        color: #ec8500;
      }
    }

    & .college_name {
      display: flex;
      align-items: center;
      gap: 6px;
      // width: 325px;
      font-weight: 400;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
  }
}

@media screen and (max-width: 1201px) {
  .direct_admission_container {
    & .tab_content {
      grid-template-columns: auto auto;
    }
  }
}

@media screen and (max-width: 992px) {
  .direct_admission_container {
    & .tab_content {
      grid-template-columns: auto;
    }

    & .tab {
      padding: 10px 5px !important;
    }

    & .tab_content {
      padding: 10px 5px 60px !important;
    }

    & .tab,
    & .tab.active {
      border: none;
    }
  }
}
