.common_contact_button {
  .contact_button {
    .cssbuttons-io-button {
      background: #f78c1f;
      color: white;
      font-family: inherit;
      padding: 0.35em;
      padding-left: 1.2em;
      font-size: 17px;
      font-weight: 500;
      border-radius: 0.9em;
      border: none;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      box-shadow: inset 0 0 1.6em -0.6em #f78c1f;
      overflow: hidden;
      position: relative;
      height: 2.8em;
      padding-right: 3.3em;
      cursor: pointer;
    }

    .cssbuttons-io-button .icon {
      background: white;
      margin-left: 1em;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.2em;
      width: 2.2em;
      border-radius: 0.7em;
      box-shadow: 0.1em 0.1em 0.6em 0.2em #f78c1f;
      right: 0.3em;
      transition: all 0.3s;
    }

    .cssbuttons-io-button:hover .icon {
      width: calc(100% - 0.6em);
    }

    .cssbuttons-io-button .icon svg {
      width: 1.1em;
      transition: transform 0.3s;
      color: #f78c1f;
    }

    .cssbuttons-io-button:hover .icon svg {
      transform: translateX(0.1em);
    }

    .cssbuttons-io-button:active .icon {
      transform: scale(0.95);
    }
  }

  .whatsapp_button {
    .cssbuttons-io-button {
      background: #25d366;
      color: white;
      font-family: inherit;
      padding: 0.35em;
      padding-left: 1.2em;
      font-size: 17px;
      font-weight: 500;
      border-radius: 0.9em;
      border: none;
      letter-spacing: 0.05em;
      display: flex;
      align-items: center;
      box-shadow: inset 0 0 1.6em -0.6em #714da6;
      overflow: hidden;
      position: relative;
      height: 2.8em;
      padding-right: 3.3em;
      cursor: pointer;
    }

    .cssbuttons-io-button .icon {
      background: white;
      margin-left: 1em;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2.2em;
      width: 2.2em;
      border-radius: 0.7em;
      box-shadow: 0.1em 0.1em 0.6em 0.2em #25d366;
      right: 0.3em;
      transition: all 0.3s;
    }

    .cssbuttons-io-button:hover .icon {
      width: calc(100% - 0.6em);
    }

    .cssbuttons-io-button .icon svg {
      width: 1.1em;
      transition: transform 0.3s;
      color: #25d366;
    }

    .cssbuttons-io-button:hover .icon svg {
      transform: translateX(0.1em);
    }

    .cssbuttons-io-button:active .icon {
      transform: scale(0.95);
    }
  }
}
